.App {
  text-align: center;
}

.App-logo {
  height: 18vmin;
  pointer-events: none;
  margin-top: 5vmin;
}

.App-header {
  background-image: url('../public/background.gif');
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link-container {
  margin: 25px 0;
  padding: 30px;
  background-color: rgba(20, 60, 80, 0.85);
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
}

.App-link-row {
  margin-top: 20px;
  display: grid;
  justify-content: center;
  text-align: left;
  justify-items: stretch;
  align-items: center;
}

.App-link-label {
  font-size: 1.1rem;
  grid-column: 1;
  margin-right: 15px;
}

.App-link {
  color: #ff8c01;
  font-size: 1.25rem;
  grid-column: 2;
}

p {
  margin: 0;
  font-size: 1.5rem;
}